class SearchPopup extends HTMLElement {
  constructor() {
    super();
    this.input = this.querySelector('[data-role="search-input"]');
    this.form = this.querySelector("form");
    this.toggleButton = this.querySelectorAll('[data-role="toggle-result"]');
    this.productContainer = this.querySelector('[data-slot="products"]');
    this.articleContainer = this.querySelector('[data-slot="articles"]');
    this.popularContainer = this.querySelector('[data-role="popular"]');
    this.resultContainer = this.querySelector(
      '[data-role="results-container"]'
    );
    this.clearButton = this.querySelector('[data-role="clear-input"]');
    this.emptySearch = this.querySelector('[data-role="empty-search"]');

    this.addEventListener('keydown',this.handleEnterButton.bind(this))
    this.input.addEventListener(
      "input",
      this.debounce((event) => {
        this.onChange(event);
      }, 200).bind(this)
    );
    this.toggleButton.forEach((el) => {
      el.addEventListener("click", () => {
        this.toggleResult.call(this, el.dataset.value);
      });
    });
    this.clearButton.addEventListener("click", this.clearResult.bind(this));
    this.form.addEventListener(
      "submit",
      this.handleSearchSubmission.bind(this)
    );
    document.addEventListener("click", (e) => {
      if (
        e.target.dataset.role == "search-toggle" ||
        e.target.closest('[data-action="search-toggle"]')
      ) {
        this.popupToggle.call(this, e);
      }
    });
    this.announcementBarVisibility();

    // popular searches listener
    document.addEventListener("click", (e) => {
      if (
        e.target.closest('[data-role="pop-text"]') ||
        e.target.dataset.role == "pop-text"
      ) {
        let el =
          e.target.dataset.role == "pop-text"
            ? e.target
            : e.target.closest('[data-role="pop-text"]');
        this.inputPopText.call(this, el);
      }
    });
  }
  inputPopText(el) {
    let value = el.textContent.trim().toLowerCase();
    this.input.value = value;
    this.input.dispatchEvent(new Event("input"));
  }
  connectedCallback() {
    let announcementBar = document.querySelector("[announcement-bar]");
    if (announcementBar) {
      let height = announcementBar.clientHeight;
      this.style.top = `${height}px`;
    }
  }
  handleEnterButton(e){
    if(e.key == "Enter"){
      let url = "/search?q=" + this.input.value + '&type=product';
      window.location = url;
    }
  }
  clearResult() {
    this.renderContent("");
    this.input.value = "";
    this.resultContainer.classList.remove("active");
    this.popularContainer.classList.remove("hidden");
    this.resultContainer.classList.add("hidden");
    this.emptySearch.classList.add("hidden");
  }
  toggleResult(slot) {
    Array.from(this.toggleButton).forEach((el) => {
      el.dataset.value == slot
        ? el.classList.add("active")
        : el.classList.remove("active");
    });
    if (slot == "products") {
      this.productContainer.classList.add("active");
      this.articleContainer.classList.remove("active");
    } else {
      this.articleContainer.classList.add("active");
      this.productContainer.classList.remove("active");
    }
  }
  handleSearchSubmission(e) {
    e.preventDefault();

    let inputValue = this.input.value.replace(" ", "+");
    let url = `/search?q=${inputValue}&type=product`;
    window.location = url;
  }
  getSearchResults(searchTerm) {
    this.classList.add("loading");
    this.emptySearch.classList.add("hidden");
    this.resultContainer.classList.add("hidden");
    this.resultContainer.classList.remove("active");
    this.popularContainer.classList.add("hidden");
    
    fetch(
      `/search/suggest?q=${searchTerm}&resources[type]=product,article,query&resources[options][fields]=title,product_type,variants.title,vendor,tag,body,author&section_id=predictive-search-v2`
    )
      .then((resp) => {
        if (resp.ok) {
          this.emptySearch.classList.add("hidden");
          return resp.text();
        } else {
          this.classList.remove("loading");
          this.emptySearch.classList.remove("hidden");
          throw new Error(resp.status);
        }
      })
      .then((text) => {
        let resultHtml = new DOMParser().parseFromString(text, "text/html");
        if (resultHtml.querySelector("#predictive-search-results")) {
          this.renderContent(resultHtml);
          this.emptySearch.classList.add("hidden");
          this.resultContainer.classList.remove("hidden");
        } else {
          this.emptySearch.classList.remove("hidden");
          let empty_text = this.emptySearch.querySelector('[data-empty]').dataset.empty.replace('**searchTerm**', searchTerm );
          this.emptySearch.querySelector('[data-empty]').innerHTML = empty_text;
          this.resultContainer.classList.remove("active");
          this.resultContainer.classList.add("hidden");
        }
        this.classList.remove("loading");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  initializePopularSlider() {
    if (window.innerWidth > 1024) {
      let Slider = window.Slider;
      let slide = new Slider(
        "#popular-sellers-search-popup-v2",
        {
          loop: true,
          slideChanged: (s) => {
            s.emit("slideupdated", s.track);
          },
          slides: {
            perView: 1.2,
            spacing: 2,
          },
          breakpoints: {
            "(min-width: 768px)": {
              slides: {
                perView: 2.6,
                spacing: 10,
              },
            },
            "(min-width: 1024px)": {
              slides: {
                perView: 3.6,
                spacing: 10,
              },
            },
            "(min-width: 1300px)": {
              slides: {
                perView: 3.8,
                spacing: 2,
              },
            },
          },
        },
        []
      );
    }
  }
  renderContent(html) {
    //console.log(html)
    let contentToRender = [
      '[data-role="suggestion-text"]',
      '[data-role="suggestion-products"]',
      '[data-role="suggestion-articles"]',
      '[data-role="result-articles-btn"]',
      '[data-role="result-products-btn"]',
      '[data-role="results-articles-count"]',
      '[data-role="results-products-count"]',
    ];
    contentToRender.forEach((el, ind) => {
      html != "" && html.querySelector(el)
        ? (this.querySelector(el).innerHTML = html.querySelector(el).innerHTML)
        : (this.querySelector(el).innerHTML = "");
      html == "" || !html.querySelector(el)
        ? (this.querySelector(el).innerHTML = "")
        : "";
    });
    this.popularContainer.classList.add("hidden");
    this.resultContainer.classList.add("active");
  }
  onChange(e) {
    const searchTerm = e.target.value.trim();
    if (searchTerm.length > 0) {
      this.getSearchResults(searchTerm);
    } else {
      this.displayEmptyState.call(this);
    }
  }
  displayEmptyState() {
    this.popularContainer.classList.remove("hidden");
    this.resultContainer.classList.remove("active");
    // this.initializePopularSlider.call(this);
  }
  debounce(fn, wait) {
    let t;
    return (...args) => {
      clearTimeout(t);
      t = setTimeout(() => fn.apply(this, args), wait);
    };
  }
  popupToggle(e) {
    e.preventDefault();
    this.classList.toggle("active");
    if (this.classList.contains("active")) {
      document.body.style.overflow = "hidden";
      // this.initializePopularSlider.call(this);
    } else {
      document.body.style.overflow = "auto";
    }
    this.input.focus();
    // this.clearResult("");
  }
  announcementBarVisibility() {
    let announcementBar = document.querySelector('[data-role="announcement"]');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const intersecting = entry.isIntersecting;
        if (intersecting) {
          this.style.top = announcementBar.clientHeight + "px";
        } else {
          this.style.top = "0px";
        }
      });
    });
    observer.observe(announcementBar);
  }
}
customElements.define("search-popup", SearchPopup);
//console.log("search popup loaded")
